















import { Vue, Component } from "vue-property-decorator";

import Topbar from "@/components/Topbar.vue";
import Navbar from "@/components/Navbar.vue";
import Footer from "@/components/Footer.vue";

@Component({
    components: {
        Topbar,
        Navbar,
        Footer
    }
})
export default class App extends Vue {}
