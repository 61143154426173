import Vue from "vue";
import Vuetify from "vuetify/lib";
import i18n from "@/plugins/i18n";

import { de, en, nl, fr } from "vuetify/src/locale";

// Installs the vuetify plugin
Vue.use(Vuetify);

// Exports a new vuetify instance
export default new Vuetify({
    lang: {
        locales: { de, en, nl, fr },
        current: i18n.locale,
    },
});
