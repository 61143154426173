import Vue from 'vue';
import App from './App.vue';
import router from './router';
import i18n from './plugins/i18n';

import '@mdi/font/css/materialdesignicons.css';
import vuetify from './plugins/vuetify';

// Fonts
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

// AOS
import AOS from 'aos';
import 'aos/dist/aos.css';

AOS.init({ 
    once: true,
    duration: 1000
});

Vue.config.productionTip = false;

new Vue({
    router,
    vuetify,
    i18n,
    render: h => h(App)
}).$mount('#app');

